module.exports = [{
      plugin: require('C:/Disks/e/Study_Folders_V1.1/My_Projects/mou_blog/blog-code/bongess-blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"linkImagesToOriginal":false},
    },{
      plugin: require('C:/Disks/e/Study_Folders_V1.1/My_Projects/mou_blog/blog-code/bongess-blog/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Disks/e/Study_Folders_V1.1/My_Projects/mou_blog/blog-code/bongess-blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-179114978-1","head":true,"respectDNT":true,"cookieDomain":"curvybongess.com"},
    },{
      plugin: require('C:/Disks/e/Study_Folders_V1.1/My_Projects/mou_blog/blog-code/bongess-blog/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans","Rock Salt","Mansalva","Lily Script One"]}},
    },{
      plugin: require('C:/Disks/e/Study_Folders_V1.1/My_Projects/mou_blog/blog-code/bongess-blog/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Curvy Bongess","short_name":"curvybongess","start_url":"/","background_color":"#fff","theme_color":"#9c7c38","display":"minimal-ui","icon":"static/images/logo.png","include_favicon":false},
    },{
      plugin: require('C:/Disks/e/Study_Folders_V1.1/My_Projects/mou_blog/blog-code/bongess-blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["","/blog","/about"]},
    }]
