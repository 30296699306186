// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Disks\\e\\Study_Folders_V1.1\\My_Projects\\mou_blog\\blog-code\\bongess-blog\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-template-jsx": () => import("C:\\Disks\\e\\Study_Folders_V1.1\\My_Projects\\mou_blog\\blog-code\\bongess-blog\\src\\templates\\blog-post-template.jsx" /* webpackChunkName: "component---src-templates-blog-post-template-jsx" */),
  "component---src-templates-blog-list-template-jsx": () => import("C:\\Disks\\e\\Study_Folders_V1.1\\My_Projects\\mou_blog\\blog-code\\bongess-blog\\src\\templates\\blog-list-template.jsx" /* webpackChunkName: "component---src-templates-blog-list-template-jsx" */),
  "component---src-templates-blog-posts-by-tag-template-jsx": () => import("C:\\Disks\\e\\Study_Folders_V1.1\\My_Projects\\mou_blog\\blog-code\\bongess-blog\\src\\templates\\blog-posts-by-tag-template.jsx" /* webpackChunkName: "component---src-templates-blog-posts-by-tag-template-jsx" */),
  "component---src-templates-blog-posts-by-author-template-jsx": () => import("C:\\Disks\\e\\Study_Folders_V1.1\\My_Projects\\mou_blog\\blog-code\\bongess-blog\\src\\templates\\blog-posts-by-author-template.jsx" /* webpackChunkName: "component---src-templates-blog-posts-by-author-template-jsx" */),
  "component---src-pages-404-jsx": () => import("C:\\Disks\\e\\Study_Folders_V1.1\\My_Projects\\mou_blog\\blog-code\\bongess-blog\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("C:\\Disks\\e\\Study_Folders_V1.1\\My_Projects\\mou_blog\\blog-code\\bongess-blog\\src\\pages\\about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-authors-jsx": () => import("C:\\Disks\\e\\Study_Folders_V1.1\\My_Projects\\mou_blog\\blog-code\\bongess-blog\\src\\pages\\blog\\authors.jsx" /* webpackChunkName: "component---src-pages-blog-authors-jsx" */),
  "component---src-pages-blog-tags-jsx": () => import("C:\\Disks\\e\\Study_Folders_V1.1\\My_Projects\\mou_blog\\blog-code\\bongess-blog\\src\\pages\\blog\\tags.jsx" /* webpackChunkName: "component---src-pages-blog-tags-jsx" */),
  "component---src-pages-index-jsx": () => import("C:\\Disks\\e\\Study_Folders_V1.1\\My_Projects\\mou_blog\\blog-code\\bongess-blog\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

